import { PayloadAction, createSlice } from "@reduxjs/toolkit";

interface BoxStateType {
	awbCode: string;
	boxExploited: number;
	box: { boxCode: string; status: string }[];
}

interface BoxStateExploit {
	awbCode: string;
	boxExploited: number;
	box: { boxCode: string; status: string };
}

const BoxInitialStates: BoxStateType[] = [];

const Box = createSlice({
	name: "box",
	initialState: BoxInitialStates,
	reducers: {
		initExploitTracking: (
			state,
			action: PayloadAction<BoxStateExploit>,
		) => {
			let newState: BoxStateType[] = [...state];
			if (newState.length === 0) {
				newState = [
					{
						awbCode: action.payload.awbCode,
						boxExploited: action.payload.boxExploited,
						box: [action.payload.box],
					},
				];
				return [...newState];
			} else {
				let checkExistAwb: BoxStateType[] = [];
				checkExistAwb = state.filter(
					(item) => item.awbCode === action.payload.awbCode,
				);
				if (checkExistAwb.length === 0) {
					newState = [
						...newState,
						{
							awbCode: action.payload.awbCode,
							boxExploited: action.payload.boxExploited,
							box: [action.payload.box],
						},
					];
					return [...newState];
				} else {
					const stateExploit: BoxStateType[] = newState.map(
						(item) => {
							if (item.awbCode === action.payload.awbCode) {
								if (
									item.box
										.map((box) => box.boxCode)
										.includes(action.payload.box.boxCode)
								) {
									return {
										...item,
										boxExploited:
											action.payload.boxExploited,
										box: item.box.map((box) => {
											if (
												box.boxCode ===
												action.payload.box.boxCode
											) {
												return action.payload.box;
											} else {
												return box;
											}
										}),
									};
								} else {
									return {
										...item,
										boxExploited:
											action.payload.boxExploited,
										box: [...item.box, action.payload.box],
									};
								}
							} else {
								return item;
							}
						},
					);
					return [...stateExploit];
				}
			}
		},
	},
});

// Actions
export const { initExploitTracking } = Box.actions;

// Reducer
export default Box.reducer;
