import { useEffect } from "react";
import { getAccessToken } from "../utils/getAccessToken";
import { useHistory } from "react-router";

const ActionLogout = () => {
	const history = useHistory();
	useEffect(() => {
		const access_token = getAccessToken();
		if (access_token === null || undefined) {
			history.push("/auth/login");
		}
	}, []);

	return null;
};

export default ActionLogout;
