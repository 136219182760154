import { getUserInfor } from "./getUserInfor";
import moment from "moment";

export const converseDate = (data: string) => {
	const localDateTime = moment.utc(data).local();

	const day = localDateTime.format("DD");
	const month = localDateTime.format("MM");
	const year = localDateTime.format("YYYY");
	const hours = localDateTime.format("HH");
	const minutes = localDateTime.format("mm");
	const seconds = localDateTime.format("ss");

	const outputTime = `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
	return outputTime;
};

export const converseDateTracking = (data: string) => {
	const date = new Date(data);

	const year = date.getFullYear();
	const month = String(date.getMonth() + 1).padStart(2, "0"); // Tháng trong JavaScript bắt đầu từ 0
	const day = String(date.getDate()).padStart(2, "0");

	const formattedDate = `${year}-${month}-${day}`;
	return formattedDate;
};

export const converseDatePicker = (dateString: string) => {
	const format = new Date(dateString);
	return format;
};

export const converstISO = (originalDateString: string) => {
	// chuyển dạng Giờ Đông Dương về dạng ISO
	const dateTimeInGMT7 = new Date(originalDateString);
	const dateTimeInUTC = new Date(
		dateTimeInGMT7.getTime() - dateTimeInGMT7.getTimezoneOffset() * 60000,
	);

	return dateTimeInUTC.toISOString();
};

export const converstNextDay = (originalTimeString: string) => {
	// Thời điểm ban đầu ở múi giờ Đông Dương (GMT+7)
	const dateTimeInGMT7 = new Date(originalTimeString);
	const dateTimeInUTC = new Date(
		dateTimeInGMT7.getTime() - dateTimeInGMT7.getTimezoneOffset() * 60000,
	);
	const dateTimeInUTCNextDay = new Date(dateTimeInUTC.getTime() + 86400000);

	return dateTimeInUTCNextDay.toISOString();
};

export const roundToTwoDecimal = (num: number) => {
	var formattedNumber = parseFloat(num.toString()).toFixed(2);
	formattedNumber = parseFloat(formattedNumber).toLocaleString("en-US");
	if (formattedNumber.indexOf(".") === -1) {
		formattedNumber += ".00";
	} else if (formattedNumber.split(".")[1].length === 1) {
		formattedNumber += "0";
	}
	return formattedNumber;
};

export const checkDuplicateCode = (objects: any) => {
	let codeMap = new Map();

	for (let obj of objects) {
		if (codeMap.has(obj.code)) {
			return `Mã tracking ''${obj.code}'' bị trùng lặp.`;
		}
		codeMap.set(obj.code, true);
	}

	return null;
};

export const convertNumberToShortForm = (number: number) => {
	var isNegative = false;
	if (number < 0) {
		isNegative = true;
		number = Math.abs(number);
	}

	if (number >= 1e9) {
		return (isNegative ? "-" : "") + (number / 1e9).toFixed(1) + "B";
	} else if (number >= 1e6) {
		return (isNegative ? "-" : "") + (number / 1e6).toFixed(1) + "M";
	} else {
		return (isNegative ? "-" : "") + number.toString();
	}
};

export const converstFormatMoney = (money: number) => {
	return Math.ceil(money);
};

export const converstFormatWeight = (weight: number) => {
	return roundToTwoDecimal(weight);
};

export const checkPermision = (name: string) => {
	const userInfor = getUserInfor();
	if (userInfor?.role.actions.includes(name)) {
		return true;
	} else {
		return false;
	}
};

export const converstDateInput = (date: string) => {
	const localDateTime = moment.utc(date).local();
	const day = localDateTime.format("DD");
	const month = localDateTime.format("MM");
	const year = localDateTime.format("YYYY");
	const outputTime = `${year}-${month}-${day}`;
	return outputTime;
};
